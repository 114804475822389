// Video Player

import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player'

import playicon from "../img/play.svg";
import seekback from "../img/replay_10.svg";
import seekforward from "../img/forward_10.svg";

function ContentVideo(props) {

  const ref = useRef(null)


  const [playing, setplaying] = useState(false);
  const [ready, setready] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!props.active) {
      setplaying(false);
    } 
  }, [props.active])

  useEffect(() => {
    console.log(seconds);
  }, [seconds])


  return (
    <div className="page-content-video" onClick={() => setplaying(!playing)}>
      <ReactPlayer 
        ref={ref}
        playing={playing}
        url={props.baseurl + props.content.url} 
        width='100%'
        height='100%'
        playsinline
        /*loop*/
        onEnded={() => props.onEnd()}
        onProgress={(e) => setSeconds(e.played)}
        /*onReady={() => setready(true)}*/
      />

      <div className='videoprogress'><div className='videoprogressinner' style={{width: seconds*100 + "%"}}></div></div>

      <div className={`videoplayicon ${playing ?  "videoplayiconhidden" : null}`}><img src={playicon} /></div>

    {/*
      <div className={`videocontrols ${playing &&  "videocontrolshidden"}`}>
        <img onClick={() => ref.current?.seekTo(ref.current?.getCurrentTime() - 10)} src={seekback} className='seekcontrolimage clickable' />
        <div className="videoplayicon"><img src={playicon} /></div>
        <img onClick={() => ref.current?.seekTo(ref.current?.getCurrentTime() + 10)} src={seekforward} className='seekcontrolimage clickable' />
      </div>
      */} 
    </div>
    
  );
}

export default ContentVideo;
