import { useState, useRef, useEffect, useMemo } from 'react';
import './mapnavigation.css';
import markerbg from "./img/markerbg.png";

import homeicon from "./img/home.svg";
import timericon from "./img/timer.svg";
import closeicon from "./img/close.svg";
import closeiconwhite from "./img/close-white.svg";
import checkmark from "./img/checkmark.svg";
import locationicon from "./img/location.svg";
import lockicon from "./img/lock-white.svg";

import pinplaceholder from "./img/pin-placeholder.png";

import PoiDot from './components/PoiDot';
import Notification from './components/Notification';

import Map, { Marker, Popup, Source, Layer } from 'react-map-gl/maplibre';
import { FlyToInterpolator } from '@deck.gl/core';

import { translate } from './functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';

import LoadingScreen from './LoadingScreen';
import LoadingFade from './LoadingFade';
import StationImage from './components/StationImage';

import { handleApiResponse } from './functions/handleApiResponse.js';

import MapProgress from './components/MapProgress';

import PositionDot from './components/PositionDot';

import ModalStationProgress from './components/ModalStationProgress';
import ModalLocationInfo from './components/ModalLocationInfo';

import Badge from './components/Badge.js';

function MapNavigation() {


  let { regionid, trailid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const mapref = useRef(null);

  const [currentpoi, setcurrentpoi] = useState(-1);
  const [stationopen, setstationopen] = useState(false);
  const [currentstation, setcurrentstation] = useState(0);

  const [currentposition, setcurrentposition] = useState(null);
  const [stationdistances, setstationdistances] = useState([]);
  const [orientationAngle, setOrientationAngle] = useState(null);

  const [geojson, setgeojson] = useState(null);

  const [collectbadgemode, setCollectbadgemode] = useState(false);

  const [badgemode, setBadgemode] = useState({mode: "end"});

  const [viewport, setViewport] = useState({
    latitude: 47.269200,
    longitude: 11.404100,
    zoom: 10,
  });

  const [notifications, setNotifications] = useState([]);

  const [stationprogressmodalopen, setstationprogressmodalopen] = useState(false);
  const [locationinfomodalopen, setlocationinfomodalopen] = useState(false);


  const shiftStations = (stations, amount) => {
    if (!stations || stations.length === 0 || amount <= 0 || amount >= stations.length) return stations;

    // Shift lat and long back by the specified amount
    const latitudes = stations.map(station => station.lat);
    const longitudes = stations.map(station => station.long);

    // Rotate latitudes and longitudes backward
    const shiftedLatitudes = [...latitudes.slice(amount), ...latitudes.slice(0, amount)];
    const shiftedLongitudes = [...longitudes.slice(amount), ...longitudes.slice(0, amount)];

    const finalPositions = stations.map((station, index) => ({
      ...station,
      lat: shiftedLatitudes[index],
      long: shiftedLongitudes[index]
  }))
    // Reassign the shifted lat and long to the stations
    console.log(finalPositions);
    return finalPositions;
};
  


  const badgePosition = useMemo(() => {
    if(data.stations != undefined) {
      if(badgemode?.mode == "roundtrip"){
        return [data.stations[0].lat, data.stations[0].long];
      } else if (badgemode?.mode == "custom") {
        return [badgemode.position.lat, badgemode.position.long];
      } else {
        return [data.stations[data.stations.length - 1].lat, data.stations[data.stations.length - 1].long];
      }
    }
  },[data]);
  

  function addNotification(template) {
    let findnotification = notifications.findIndex(notification => notification.template === template);
    if(findnotification == -1) {
      setNotifications(items => [...items, {template: template}])
    }
  }

  function removeNotification(index) {
    let temp = [...notifications];
    temp.splice(index, 1);
    setNotifications(temp)
  }

  function handleFlyTo(index) {
    /*
    const newViewport = {
      ...viewport,
      latitude: parseFloat(data.stations[index].lat),
      longitude: parseFloat(data.stations[index].long),
      zoom: 12,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 2000,
    };
  
    setViewport(newViewport);*/
    mapref.current.flyTo({ center: [data.stations[index].long, data.stations[index].lat], zoom: 16 });
  }

  function flytoCurrentPosition() {
    mapref.current.flyTo({ center: [currentposition[1], currentposition[0]], zoom: 16 });
  }


  function toggletooltip(index) {
    setstationopen(false);
    if (index == currentpoi) {
      setcurrentpoi(-1);
    } else {
      setcurrentpoi(index)
    }
  }

  function togglestation(index) {
    setcurrentpoi(-1);
    setstationprogressmodalopen(false);
    if (index == currentstation && stationopen) {
      setstationopen(false);
    } else {
      handleFlyTo(index);
      setcurrentstation(index);
      setstationopen(true)
    }
    //mapref.current?.flyTo({center: [data[index]?.long, data[index]?.lat], transitionDuration: 3000})
  }

  function openstationpopup(index) {
      handleFlyTo(index);
      setcurrentstation(index);
      setstationopen(true)
  }

  useEffect(() => {
    var posID = navigator.geolocation.watchPosition(locationSuccess, locationError, { enableHighAccuracy: true });
    fetchData();
    fetchgeojson();
    return () => {
      navigator.geolocation.clearWatch(posID);
    }
  }, []);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}getMap.php?region=${regionid}&trailid=${trailid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      if (data.code !== undefined) {
        handleApiResponse(data.code, regionid, navigate);
      } else {
        //setData(data);
        const shiftAmountnew = localStorage.getItem(`shiftstations-${trailid}`);
        setData({
          ...data,
          stations: shiftStations(data.stations, shiftAmountnew),
        });
        let newbadgemode = JSON.parse(data.badgemode || "null");
        //console.log(newbadgemode);
        if(newbadgemode?.mode !== undefined) {
          setBadgemode(newbadgemode);
        }
        if (data.stations.every(station => station.done)) {
          navigate(`/${regionid}/success/${trailid}`);
          /*
          setCollectbadgemode(true);
          addNotification("done");
          // wenn Devmode, geh direkt zum Badge
          if(localStorage.getItem("devmode") == 1) {
            navigate(`/${regionid}/success/${trailid}`);
          }*/
        };

        let nextstation = data.stations.findIndex(station => station.done === false);
        let donestations = data.stations.filter(station => station.done);
  
        if(donestations.length == 0) {
          addNotification("letsgo");
        }

        if(donestations.length == data.stations.length-1) {
          addNotification("laststation");
        }

        if(nextstation >= 0) {
          setViewport({
            latitude: data.stations[nextstation].lat,
            longitude: data.stations[nextstation].long,
            zoom: 11
          });
        } else {
          setViewport({
            latitude: data.stations[data.stations.length - 1].lat,
            longitude: data.stations[data.stations.length - 1].long,
            zoom: 11
          });
        }
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  function initialzoom() {
    let nextstation = data.stations.findIndex(station => station.done === false);
    if(collectbadgemode) {
      mapref?.current?.flyTo({ center: [badgePosition[1], badgePosition[0]], zoom: 16, speed: 0.5 });
    } else {
      mapref?.current?.flyTo({ center: [data.stations[nextstation].long, data.stations[nextstation].lat], zoom: 16, speed: 0.5 });
    }
    
  }

  const fetchgeojson = async () => {
    fetch(
      `${process.env.REACT_APP_CONTENT_URL}${regionid}/${trailid}/route.geojson`
    )
      .then(resp => resp.json())
      .then(json => setgeojson(json))
      .catch(err => console.error('Could not load data', err)); // eslint-disable-line
  };

  const lineStyle = {
    id: 'line',
    type: 'line',
    paint: {
      "line-color": "#71A8FB",
      "line-width": 5,
    },
    layout: {
      "line-cap": "round"
    }
  };



  // location fetched successfully
  function locationSuccess(position) {
    console.log(position.coords); 
    setcurrentposition([position.coords.latitude, position.coords.longitude]);
  }

  // location fetching failed
  function locationError(error) {
    if(error.code == 1) {
      addNotification("nolocation");
    }
    console.log("Error Code: " + error.code);
    console.log("Error Message: " + error.message);
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // Earth radius in meters
    const phi1 = (lat1 * Math.PI) / 180;
    const phi2 = (lat2 * Math.PI) / 180;
    const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;
  
    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = Math.round(R * c); // Distance in meters rounded to the nearest integer
    return distance;
  }

  useEffect(() => {
    if(currentposition != null && data.stations != undefined) {
      const distances = data.stations.map(station =>
        calculateDistance(currentposition[0], currentposition[1], station.lat, station.long)
      );
      setstationdistances(distances);
      const belowTenIndex = distances.findIndex(distance => distance <= 20);

      if (belowTenIndex !== -1 && data.stations[belowTenIndex]?.done == false) {
          //navigator.vibrate(200);
          if(!collectbadgemode) {
            openstationpopup(belowTenIndex);
          }
      }
    }
  }, [currentposition])

  function getStationButton() {
    if(currentposition != null) {
      if(data.stations[currentstation]?.done) {
        return <div onClick={() => navigate(`/${regionid}/station/${data.stations[currentstation]?.stationid}`)} className='openstationbtn openstationbtnactive clickable'>{translate("openagain", data.language)}</div>
      } else {
        if(stationdistances[currentstation] <= 20) {
          return <div onClick={() => navigate(`/${regionid}/station/${data.stations[currentstation]?.stationid}`)} className='openstationbtn openstationbtnactive clickable'>{translate("opennow", data.language)}</div>
        } else {
          return <div className='openstationbtn' style={{backgroundColor: '#aaa'}}><img src={lockicon} />{stationdistances[currentstation] ? `${translate("unlockdistancetext", data.language)} ${formatDistance(stationdistances[currentstation] - 20)}` : translate("moveclosertounlock", data.language)}</div>
        }
      }
    } else {
      return <div className='openstationbtn' style={{backgroundColor: '#aaa'}}>{translate("nolocation", data.language)}</div>
    }
  }

  function getBadgeTooltip() {
    if(currentposition && badgePosition) {
      console.log(currentposition);
      console.log(badgePosition);
      var badgedistance = calculateDistance(currentposition[0], currentposition[1], badgePosition[0], badgePosition[1]);
      if(badgedistance != undefined) {
        if(badgedistance <= 20) {
          return <div className={`stationbubble stationbubble-active`} style={{fontWeight: 'bold'}}>{translate("taptocollect", data.language)}</div>
        } else {
          return <div className={`stationbubble`}>{formatDistance(badgedistance - 20)}</div>
        } 
      } else {
        return currentposition == null && <div className={`stationbubble`}>{translate("nolocation", data.language)}</div>
      }
    } else {
      return <div className={`stationbubble`}>{translate("nolocation", data.language)}</div>
    }
  }


  function formatDistance(distance) {
    if (distance < 1000) {
      return `${distance}m`;
    } else {
      const distanceInKm = (distance / 1000).toFixed(1);
      return `${distanceInKm}km`;
    } 
  }

  function handlenotificationclick(type) {
    if(type == "nolocation") {
      setlocationinfomodalopen(true);
    }
  }

  useEffect(() => {
    const handleOrientation = (event) => {
        const { alpha } = event; // alpha represents the compass direction
        setOrientationAngle(alpha);
        console.log(alpha);
    };

    if (window.DeviceOrientationEvent) {
        window.addEventListener("deviceorientation", handleOrientation, true);
    }

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener("deviceorientation", handleOrientation, true);
    };
}, []);


  return (
    loading ? <LoadingScreen /> : (
      <div className="page-map">
        <LoadingFade clouds={true} />
        <Map
          ref={mapref}
          initialViewState={viewport}
          style={{ position: 'fixed', width: '100vw', height: '100vh', height: '100dvh' }}
          mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${process.env.REACT_APP_MAP_KEY}`}
          onLoad={() => initialzoom()}
        >
          {geojson &&
          <Source type="geojson" data={geojson}>
            <Layer {...lineStyle} />
          </Source>
          }

          {
            data.pois.map((obj, index) => {
              return (<Marker key={"poi-" + index} longitude={obj.long} latitude={obj.lat} anchor="center" onClick={() => toggletooltip(index)}><PoiDot type={obj.type} /></Marker>)
            })
          }

          {currentpoi > -1 ? (
            <Marker longitude={data.pois[currentpoi].long} latitude={data.pois[currentpoi].lat} anchor="bottom" offset={[0, -32]}>
              <div className='maptooltip'>
                {translate("poi_" + data.pois[currentpoi].type, data.language)}
                {data.pois[currentpoi].title && <p>{data.pois[currentpoi].title}</p>}
              </div>
            </Marker>
          ) : null}

          {!collectbadgemode && 
            data.stations.map((obj, index) => {
              return (<Marker key={"marker-" + index} longitude={obj.long} latitude={obj.lat} anchor="bottom" onClick={() => togglestation(index)}>
                <div className={`stationmarker ${!obj.done && stationdistances[index] <= 20 && "stationmarkeractive"}`}>
                  {(!obj.done && stationdistances[index] > 20) && <div className={`stationbubble ${(stationopen && currentstation == index) && "stationbubble-active"}`}>{formatDistance(stationdistances[index] - 20)}</div>}
                  {obj.done && <div className='stationcheckmark' style={{ width: 24, height: 24 }}><img src={checkmark} style={{ width: 16 }} /></div>}
                  <img src={markerbg} className='markerbg' />
                  <StationImage image={obj.image} style={{ width: 51, height: 51, position: 'absolute', top: 7 }} />
                </div>
              </Marker>)
            })
          }

          {currentposition &&
            <Marker onClick={() => flytoCurrentPosition()} key={"currentposition"} longitude={currentposition[1]} latitude={currentposition[0]} anchor="center">
              <PositionDot orientation={orientationAngle} />
            </Marker>
          }

          {
            collectbadgemode && 
            <Marker key={"badge"} longitude={badgePosition[1]} latitude={badgePosition[0]} anchor="center" >
                <div className={`stationmarker stationmarkerbadge`} onClick={() => (currentposition && badgePosition) && (calculateDistance(currentposition[0], currentposition[1], badgePosition[0], badgePosition[1]) <= 20) ? navigate(`/${regionid}/success/${trailid}`) : null}>
                  {getBadgeTooltip()}
                  <Badge img={`${process.env.REACT_APP_CONTENT_URL}${regionid}/${trailid}/badge.png`} active={true} />
                </div>
            </Marker>
          }

        </Map>


        <div className={`stationpopupcontainer ${stationopen ? null : "stationpopupcontainerclosed"}`}>
          <div className='stationpopup'>
            {(process.env.NODE_ENV === 'development' || localStorage.getItem("devmode") == 1) && <div onClick={() => navigate(`/${regionid}/station/${data.stations[currentstation]?.stationid}`)} style={{position: 'absolute', opacity:0.3, top: 0, left: 0, padding: 8, paddingLeft: 16, fontSize: 14}}>Cheat</div>}
            <div className='stationpopupcloseicon clickable' onClick={() => setstationopen(false)}><img src={closeicon} /></div>
            <StationImage image={data.stations[currentstation]?.image} style={{ width: 56, height: 56, position: 'absolute', top: -30, border: "3px solid #fff", boxShadow: "0px 6px 8px #0003" }} />
            <p>{data.stations[currentstation]?.title}</p>
            {getStationButton()}
          </div>
        </div>

        {/*<div className='mapbuttonbadge homebutton' onClick={() => navigate(`/${regionid}`)} style={{opacity: stationopen ? 0 : 1}}><img src={homeicon} /></div>*/}

        <div className='closebutton' onClick={() => navigate(`/${regionid}`, { state: { trail: trailid } })}><img src={closeiconwhite} /></div>



        {/*<div className='mapbuttonbadge timebadge' style={{opacity: stationopen ? 0 : 1}}><img src={timericon} />{"04:12"}</div>*/}

        <div onClick={() => setstationprogressmodalopen(true)}><MapProgress total={data.stations.length} done={data?.stations?.filter(station => station.done).length} points={253} /></div>


          <div className={`notificationwrapper ${stationopen && "notificationwrapper-closed"}`}>
          {currentposition &&
            <div className='locationbutton clickable' onClick={() => flytoCurrentPosition()} ><img src={locationicon} /></div>
          }

            {
              notifications.map((obj, index) => 
                <Notification key={index} template={obj.template} lang={data.language} onRemove={() => removeNotification(index)} onButtonClick={(x) => handlenotificationclick(x)} />
              )
            }
          </div>

          <ModalStationProgress stations={data.stations} open={stationprogressmodalopen} onClose={() => setstationprogressmodalopen(false)} onSelect={(x) => togglestation(x)} total={data.stations.length} done={data?.stations?.filter(station => station.done).length} />

          <ModalLocationInfo open={locationinfomodalopen} onClose={() => setlocationinfomodalopen(false)} lang={data.language || "en"} />
        

      </div>
    )
  );
}

export default MapNavigation;
