import { useState, useRef, useEffect } from 'react';
import './App.css';
import Overview from './Overviewnew3';
import TrailDetail from './TrailDetail';
import MapNavigation from './MapNavigationnew2';
import Station from './Station2';
import Success from './Success-new';
import LanguagePicker from './LanguagePicker';
import RegionRoot from './RegionRoot';
import ErrorBoundary from './ErrorBoundary';
import LoadingScreen from './LoadingScreen';
import RegionOverview from './RegionOverview';
import Teststation from './TestStation';
import Onboarding from './Onboarding';

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Routes,
  Route,
  BrowserRouter,
  useParams,
  redirect,
  Link,

} from "react-router-dom";




function App() {

  const loggedin = localStorage.getItem('uuid') != null;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get('source');
    const trailId = searchParams.get('trailid') || searchParams.get('trail_id');
    const regionid = window.location.pathname.slice(1);

    if(trailId) {
      localStorage.setItem("opentrail", trailId);
    }
    if(source) {
      handleAnalytics(source, trailId, regionid);
    }
    
  }, []);

  const handleAnalytics = async (source, trailId, regionid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}analytics_source.php?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ source, trailId, regionid}),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Failed to send analytics:', error);
    }
  }
  
  const router = createBrowserRouter([
    {
      path: "/:regionid",
      element: <RegionRoot/>,
      errorElement: <ErrorBoundary/>,
      children: [
        {
          path: "",
          element: <Overview/>,
        },
        {
          path: "start",
          element: <Onboarding/>,
        },
        
        {
          path: "map/:trailid",
          element: <MapNavigation/>,
        },
        {
          path: "station/:stationid",
          element: <Station/>,
        },
        {
          path: "success/:trailid",
          element: <Success/>,
        },
        {
          path: "teststation",
          element: <Teststation/>,
        },
      ]
    },
    {
      path: "/",
      element: <RegionOverview/>,
      errorElement: <ErrorBoundary/>,
    }
  ]);

  const login = createBrowserRouter([
    {
      path: "*",
      element: <LoadingScreen newuser/>,
    },
  ])


  return (
    
    loggedin ?
    <RouterProvider router={router} />
    :
    <RouterProvider router={login} />
  );
}



export default App;
