// Video Player

import { useState, useRef, useEffect } from 'react';

function ContentImage(props) {


  return (
    <div className="page-content-image">
      <img src={props.baseurl + props.content.url} />
    </div>
  );
}

export default ContentImage;
