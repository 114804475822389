// Start page where user selects language

import { useState, useRef, useEffect } from 'react';
import languageinfo from './languageinfo';
import './langswitcher.css';
import { translate } from './functions/translationHelper';
import LoadingScreen from './LoadingScreen';

import { useParams, useNavigate } from 'react-router-dom';


function Onboarding() {

  let { regionid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [slidesindex, setSlidesIndex] = useState(0);
  const [data, setData] = useState();
  const carouselRef = useRef();

  const mockdata = [
    {
      language: "de",
      privacylink: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals",
      slides: [
        {
          headline: "Erlebe unsere digitalen Erlebniswege",
          text: "Genieße die Tiroler Zugspitz Arena jetzt mit spannenden und interaktiven Geschichten. Du benötigst dafür nur dein Handy.",
          image: "https://content.wunderweg.net/zugspitzarena/slide1.png",
          imagewidth: 80
        },
        {
          headline: "Hol dir dein Tattoo!",
          text: "Für jeden abgeschlossenen Weg kannst du dir ein gratis Aufklebe-Tattoo im  Tourismusbüro holen.",
          image: "https://content.wunderweg.net/zugspitzarena/slide2.png",
          imagewidth: 240
        }
      ]
    },
    {
      language: "en",
      privacylink: "https://www.google.com/maps/d/u/1/",
      slides: [
        {
          headline: "Experience Our Digital Adventure Paths",
          text: "Enjoy the Tyrolean Zugspitz Arena now with exciting and interactive stories. All you need is your phone.",
          image: "https://content.wunderweg.net/zugspitzarena/slide1.png",
          imagewidth: 80
        },
        {
          headline: "Get Your Tattoo!",
          text: "For every completed path, you can get a free stick-on tattoo at the tourism office.",
          image: "https://content.wunderweg.net/zugspitzarena/slide2.png",
          imagewidth: 240
        }
      ]
    },
  ]

  function selectcurrentlanguage(mydata) {
    let currentlanguage = localStorage.getItem("language");
    let newindex = mydata.findIndex(item => item.language === currentlanguage);
    if (newindex >= 0) {
      setIndex(newindex);
    }
  }

  function nextslide() {
    const container = carouselRef.current;
    container.scrollBy({ left: window.innerWidth, behavior: 'smooth' });
    setSlidesIndex(slidesindex + 1);
  }

  function backtostart() {
    const container = carouselRef.current;
    container.scrollTo({ left: 0, behavior: 'smooth' });
    setSlidesIndex(0);
  }

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}getLanguageData.php?region=${regionid}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const parsedData = data.map(item => ({
          ...item,
          slides: JSON.parse(item.slides) || []
        }));
    
        setData(parsedData);

        //setData(data);
        selectcurrentlanguage(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching language data:', error.message);
        // Handle the error, e.g., set an error state
      });

  }, [])

  const creatRegionUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}createRegionUser.php`, {
        method: 'POST',
        body: JSON.stringify({ uuid: localStorage.getItem('uuid'), region: regionid, language: data[index].language })
      });

      const res = await response.json();

      console.log(res);
      localStorage.setItem("language", data[index].language);

      if (res?.success) {
        navigate(`/${regionid}`);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

    
  }

  return (
    loading ? <LoadingScreen /> :
      data.length > 0 ?
        <div className="page-onboarding">
          <div className='onboardinglanguageinfo clickable' onClick={() => backtostart()} style={{opacity: slidesindex == 0 ? 0 : 1}}><img src={languageinfo[data[index].language].image} /></div>
          <div className="carousel-container" ref={carouselRef}>
            <div className='onboardingslide'>
              <div className='wrapper'>
                <p className='onboardingheadline'>{translate("chooselanguage", data[index].language)}</p>
                <div className={`languagelist ${(data.length == 3 || data.length >= 5) && "languagelist-3"}`}>
                  {
                    data.map((obj, i) => <div key={i} className={`languageswitchercard clickable ${i == index && "languageswitchercard-active"}`} onClick={() => setIndex(i)}><img src={languageinfo[obj.language].image} /><p>{languageinfo[obj.language].name}</p></div>)
                  }
                </div>
              </div>
            </div>
            {
              data[index].slides.map((obj, i) =>
                <div className='onboardingslide' key={i}>
                  <div className='wrapper'>
                    <img src={process.env.REACT_APP_CONTENT_URL + regionid + "/" + obj.image} style={{ width: obj.imagewidth ? obj.imagewidth : '100%' }} />
                    <p className='onboardingheadline'>{obj.headline}</p>
                    <p className='onboardingtext'>{obj.text}</p>
                  </div>
                </div>
              )
            }
          </div>
          <div className='onboardingbuttonsection'>
            <div className='wrapper'>
              {data[index].slides.length > 0 &&
              <div className='onboardingsliderdots'>
                {[...Array(data[index].slides.length + 1)].map((obj, i) => <div key={i} className='onboardingsliderdot' style={{opacity: slidesindex == i ? 1 : 0.2}}></div>)}
              </div>
              }
              {slidesindex >= data[index].slides.length ?
                <div onClick={() => creatRegionUser()} className='button-primary'>{translate("letsgo", data[index].language)}</div>
                :
                <div onClick={() => nextslide()} className='button-primary'>{translate("next", data[index].language)}</div>
              }

            </div>
          </div>
        </div>
        : <div>no results</div>
  );
}

export default Onboarding;
