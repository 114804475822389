import { useRef, useEffect, useState } from 'react';

import card1 from "../img/card1.png";
import checkmark from "../img/checkmark.svg";

import getIntensity from "../functions/getIntensity";
import formatTime from "../functions/formatTime.js";
import formatDistance from "../functions/formatDistance";

import tl from "../translations";

import Tag from './Tag';

function TrailCard(props) {

  const myRef = useRef(null);

  const [rect, setRect] = useState({top: 0, bottom: 0, height: 400});



  useEffect(() => {
    const handleScroll = () => {
      if (myRef.current) {
        //console.log(myRef.current.getBoundingClientRect());
        setRect(myRef.current.getBoundingClientRect());
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call to getBoundingClientRect

    //console.log(window.innerHeight);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    
  }, []);

  function getParallaxOffset() {
    if(rect.top < window.innerHeight && rect.bottom > 0) {
      //console.log("visible!");
      let delta = window.innerHeight + rect.height;
      let scrollvalue = (rect.top + rect.height) / delta;
      //console.log(scrollvalue);
      return scrollvalue * -20;
    } else {
      return 0;
    }
  }


  

  return (
    <div ref={myRef} className="trailcard" /*style={{backgroundImage: `url(${props.img})` }}*/>

      {/*<img loading="lazy" src={props.img} style={{width: '100%', height: '120%', position: "absolute", objectFit: 'cover', top: getParallaxOffset() + '%'}} />*/}
      {props.options.usevideo ? (
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{ width: '100%', height: '120%', position: 'absolute', objectFit: 'cover', top: getParallaxOffset() + '%' }}
        >
          <source src={props.video} type="video/mp4" />
        </video>
      ) : (
        <img
          loading="lazy"
          src={props.img}
          style={{ width: '100%', height: '120%', position: "absolute", objectFit: 'cover', top: getParallaxOffset() + '%' }}
        />
      )}


      <div className='cardtagcontainer'>
      {props.customcardtag != "" && <div className={`tag`}>{props.customcardtag}</div>}
      {
        Array.isArray(props.tags) && props.tags.filter(item => item.featured).map((tag, index) => <Tag key={index} tag={tag.tag} lang={props.lang} />)
      }
      </div>


      

      {props?.options?.uselogo == false ?
      <h3 style={{color: props?.options?.textcolor ?? "#fff"}}>{props.title}</h3>
      :
      <img className="trailcardlogo" src={props.logo} style={{width: props.logoscale+"%"}} />
      }
     
      {props.done ? <div className="carddoneicon"><img src={checkmark} /></div> : null}
      <p style={{color: props?.options?.textcolor ?? "#fff"}}>{props.stations} {props.stations == 1 ? tl.station_singular[props.lang] : tl.stations[props.lang]} · {formatTime(props.time)} {tl.hours[props.lang]} · {formatDistance(props.distance)} km</p>
      {props?.options?.usegradient == false ? null : <div className="trailcardgradient"></div>}
    </div>
  );
}

export default TrailCard;
