// UPDATE 18. September: kompakter, ohne headline?, neue Badgesektion

import { useState, useRef, useEffect } from 'react';
import './App.css';
import TrailCard from './components/Trailcard';
import Badgecounter from './components/Badgecounter';
import Badge from './components/Badge';
import ModalMap from './components/ModalMap';
import ModalBadges from './components/ModalBadges';

import badgeshine from "./img/badgeshine.png";
import globe from "./img/globe.svg";
import feedbackicon from "./img/feedback.svg";

import poweredbywunderweg from "./img/poweredbywunderweg.svg";

import TrailDetail from './TrailDetail-2';

import ModalSocialShare from './components/ModalSocialShare';

//import tl from './translations';
import { translate } from './functions/translationHelper';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import LoadingFade from './LoadingFade';

import { handleApiResponse } from './functions/handleApiResponse.js';
import ModalInstallInfo from './components/ModalInstallInfo';

function Overview() {

  let { regionid } = useParams();
  const navigate = useNavigate();
  let location = useLocation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const [badgeopen, setbadgeopen] = useState(false);
  const [mapopen, setmapopen] = useState(false);

  const [installinfoopen, setinstallinfoopen] = useState(false);

  const [detailopen, setdetailopen] = useState(false);
  const [currenttrail, setcurrenttrail] = useState(0);

  const badgecontainer = useRef(null);

  const [socialshareopen, setsocialshareopen] = useState(false);

  let donetrails = data?.trails?.filter(trail => trail.done) || [];

  function opencard(id) {
    setcurrenttrail(id);
    setdetailopen(true);
  }

  function openshare(id) {
    let trailid = findcardbyid(id);
    if(trailid >= 0) {
      setcurrenttrail(trailid);
      setsocialshareopen(true);
    }
  }

  function findcardbyid(mytrailid) {
    return data?.trails?.findIndex(trail => trail.id == mytrailid);
  }

  useEffect(() => {
    if (!window.matchMedia('(display-mode: standalone)').matches && localStorage.getItem("seeninstallprompt") != "true") {
      // Website is not standalone and installinfo has not been seen
      //setinstallinfoopen(true);
    }


    fetchData();
  }, []);

  useEffect(() => {
    if(data?.trails != undefined) {
      let opentrailid = location?.state?.trail || localStorage.getItem("opentrail");
      if(opentrailid != null) {
        localStorage.removeItem("opentrail");
        let cardid = findcardbyid(opentrailid);
        if(cardid >= 0) {
          opencard(cardid);
        }
      };
    }
  }, [data]);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}overview.php?region=${regionid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      if(data.code !== undefined) {
        handleApiResponse(data.code, regionid, navigate);
      } else {
        localStorage.setItem("region", data?.slug);
        setData(data);
        setLoading(false);
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function openbadgeview() {
    localStorage.removeItem("badgenotification");
    setbadgeopen(true);
  }

  

  return (
    loading ? <LoadingScreen/> : (
    <div className="page-overview">
          <LoadingFade/>

          {/*<div className='installprompt'>
            <b>Tipp:</b> Speichere dir die Webseite auf dem Homescreen, für eine bessere Bedienung!
            <div className="traildetailbadgeboxbutton clickable" onClick={() => setinstallinfoopen(true)}>So geht's</div>
          </div>*/}
          <div className='wrapper'>
            {/*<Badgecounter/>
        <div className='badgecounterlabel'>
          <b style={{marginRight: 8}}>1/{cards.length}</b> Mein Sammelpass
          <img src={require("./img/arrow.png")} />
  </div>*/}


            <div className='overviewlistbar buildup-down' style={{animationDelay: '400ms'}}>
              <div className='mapbutton-secondary clickable' onClick={() => setmapopen(true)}>{translate("map", data.language)}</div>
              <div className='badgecountercont clickable' onClick={() => openbadgeview()}>
                {localStorage.getItem("badgenotification") == "true" && <div className='badgenotification'><div className='badgenotificationcirclebig'></div><div className='badgenotificationcirclesmall'></div></div>}
                <div style={{lineHeight: 1}}><b>{donetrails.length}</b>/{data?.trails.length}</div>
                <div style={{ backgroundImage: `url(${donetrails.length > 0 ? donetrails[0].badge : data?.trails[0].badge})` }} className='badge-detail-home'><img src={badgeshine} className="badgeshine" /></div>
              </div>
            </div>

              <div className='buildup-up'>
                <h1>{data.headline}</h1>
              </div>
            </div>

            {data.customsections?.hometop && <div dangerouslySetInnerHTML={{ __html: data.customsections?.hometop }} className='customsections-hometop'></div>}


            <div className='wrapper' >

            {
              data?.trails.map((obj, index) => <div key={index} className='buildup-up' style={{animationDelay: `${200 + (index * 150)}ms`}}><div onClick={() => opencard(index) /*openshare(index)*/} className={`cardwrapper ${detailopen && index == currenttrail ? "cardactive" : ""}`}><TrailCard lang={data.language} title={obj.title} img={obj.image} video={obj.video} logo={obj.logo} cardlogo={obj.logocard} logoscale={obj?.logoscale} done={obj.done} stations={obj.stationcount} intensity={obj.difficulty} time={obj.time} distance={obj.distance} options={JSON.parse(obj.options)} tags={JSON.parse(obj.tags)} customcardtag={obj?.customcardtag} /></div></div>)
            }
            </div>

            {data.customsections?.homebottom && <div dangerouslySetInnerHTML={{ __html: data.customsections?.homebottom }} className='customsections-homebottom'></div>}

            <div className='wrapper buildup-up' style={{marginBottom: 40, animationDelay: '500ms'}}>
              <div className="footerbutton" onClick={() => navigate(`/${regionid}/start`)}><img src={globe} />{translate("changelanguage", data.language)}</div>
              <div className="footerbutton" onClick={() => window.location = `mailto:feedback@wunderweg.com?subject=App Feedback - ${data.headline}`}><img src={feedbackicon} />{translate("sendfeedback", data.language)}</div>
              <a className='poweredbywunderweg' href='https://www.wunderweg.com/' target='_blank'><img src={poweredbywunderweg} /></a>
            </div>


          {/*<div className='mapbutton'  onClick={() => setmapopen(!mapopen)}>{mapopen ? translate("list", data.language) : translate("map", data.language)}</div>*/}
          <ModalMap open={mapopen} trails={data?.trails} onmarkerclick={(x) => opencard(x)} onClose={() => setmapopen(false)} />
          <ModalBadges open={badgeopen} onClose={() => setbadgeopen(false)} trails={data.trails} donetrails={donetrails} numberoftrails={data.trails.length} lang={data?.language} openshare={(x) => openshare(x)} />

          <TrailDetail lang={data?.language} open={detailopen} onClose={() => setdetailopen(false)} infos={data?.trails[currenttrail]} openshare={() => setsocialshareopen(true)} />
          {detailopen || badgeopen || socialshareopen ? <style>{"body{overflow:hidden;}"}</style> : null}

          <ModalSocialShare open={socialshareopen} onClose={() => setsocialshareopen(false)} lang={data?.language || "en"} infos={data?.trails[currenttrail]} regionid={regionid} />

          <ModalInstallInfo open={installinfoopen} onClose={() => setinstallinfoopen(false)} lang={data.language || "en"} slug={regionid} />
        
    </div>)

  );
}

export default Overview;
