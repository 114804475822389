const tl = {
  letsgo: {
    de: "Los geht's!",
    en: "Let's go!",
    it: "Andiamo!"
  },
  chooselanguage: {
    de: "Wähle deine Sprache",
    en: "Choose your language",
    it: "Scegli la tua lingua"
  },
  consenttext: {
    de: "Ich stimme der Datenschutzerklärung zu.",
    en: "By clicking start I accept the Privacy Policy.",
    it: "Accetto l'informativa sulla privacy."
  },
  consentbutton: {
    de: "Mehr Infos",
    en: "More info",
    it: "Ulteriori informazioni"
  },
  stickers: {
    de: "Mein Sammelpass",
    en: "My Stickers",
    it: "I miei adesivi"
  },
  firststickertext: {
    de: "Gehe einen Weg, um deinen ersten Sticker zu sammeln!",
    en: "Finish a trail to collect your first sticker!",
    it: "Completa un percorso per raccogliere il tuo primo adesivo!"
  },
  badgedatetext: {
    de: "Geschafft am",
    en: "Achieved on",
    it: "Completato il"
  },
  youdidit: {
    de: "Geschafft!",
    en: "You did it!",
    it: "Ce l'hai fatta!"
  },
  youdidittext: {
    de: "Gratulation! Du hast den Weg am ${1} absolviert.",
    en: "Congratulations! You completed the trail on ${1}",
    it: "Congratulazioni! Hai completato il percorso il ${1}"
  },
  changelanguage: {
    de: "Sprache ändern",
    en: "Change language",
    it: "Cambia lingua"
  },
  trails: {
    de: "Wege",
    en: "Trails",
    it: "Sentieri"
  },
  trail_singular: {
    de: "Weg",
    en: "Trail",
    it: "Sentiero"
  },
  stations: {
    de: "Stationen",
    en: "stations",
    it: "Stazioni"
  },
  station_singular: {
    de: "Station",
    en: "station",
    it: "Stazione"
  },
  hours: {
    de: "Stunden",
    en: "hours",
    it: "ore"
  },
  hour_singular: {
    de: "Stunde",
    en: "hour",
    it: "ora"
  },
  difficulty: {
    de: ["Leicht", "Mittel", "Schwer"],
    en: ["Easy", "Medium", "Hard"],
    it: ["Facile", "Medio", "Difficile"]
  },
  map: {
    de: "Karte",
    en: "Map",
    it: "Mappa"
  },
  list: {
    de: "Liste",
    en: "List",
    it: "Lista"
  },
  startnow: {
    de: "Jetzt starten",
    en: "Start now",
    it: "Inizia ora"
  },
  startagain: {
    de: "Nochmal starten",
    en: "Start again",
    it: "Ricomincia"
  },
  arrival: {
    de: "Startpunkt & Anreise",
    en: "Starting point & arrival",
    it: "Punto di partenza e arrivo"
  },
  route: {
    de: "Route anzeigen",
    en: "Show route",
    it: "Mostra percorso"
  },
  reload: {
    de: "Neu laden",
    en: "Reload",
    it: "Ricarica"
  },
  poi_water: {
    de: "Trinkwasser",
    en: "Drinking water",
    it: "Acqua potabile"
  },
  poi_restaurant: {
    de: "Restaurant",
    en: "Restaurant",
    it: "Ristorante"
  },
  poi_toilet: {
    de: "Toilette",
    en: "Toilet",
    it: "Servizi igienici"
  },
  poi_photopoint: {
    de: "Fotopoint",
    en: "Photopoint",
    it: "Punto foto"
  },
  poi_pin: {
    de: "Markierung",
    en: "Marking",
    it: "Marcatura"
  },
  opennow: {
    de: "Jetzt öffnen",
    en: "Open now",
    it: "Aperto ora"
  },
  openagain: {
    de: "Nochmal öffnen",
    en: "Open again",
    it: "Apri di nuovo"
  },
  nolocation: {
    de: "Kein Standort",
    en: "No location",
    it: "Nessuna posizione"
  },
  unlockdistancetext: {
    de: "Freischalten in",
    en: "Unlock in",
    it: "Sblocca tra"
  },
  moveclosertounlock: {
    de: "Bewege dich zur Station",
    en: "Move closer to unlock",
    it: "Avvicinati per sbloccare"
  },
  success_headline: {
    de: "Geschafft!",
    en: "You did it!",
    it: "Fatto!"
  },
  success_text: {
    de: "Glückwunsch, du hast alle Stationen des Weges '${1}' geschafft!",
    en: "Congratulations, you have found all stations of the '${1}' trail!",
    it: "Congratulazioni, hai trovato tutte le stazioni del percorso '${1}'!"
  },
  collect_sticker: {
    de: "Sticker einsammeln",
    en: "Collect sticker",
    it: "Raccogli lo sticker"
  },
  feedback_headline: {
    de: "Wie hat dir der Weg gefallen?",
    en: "How did you like this trail?",
    it: "Come ti è piaciuto questo percorso?"
  },
  feedback_improve: {
    de: "Danke! Was können wir verbessern?",
    en: "Thanks! What could we improve?",
    it: "Grazie! Cosa possiamo migliorare?"
  },
  feedback_fivestars: {
    de: "Das freut uns! Was hat dir am besten gefallen?",
    en: "We love to hear that! What did you like the most?",
    it: "Siamo felici di sentirlo! Cosa ti è piaciuto di più?"
  },
  skip: {
    de: "Überspringen",
    en: "Skip",
    it: "Salta"
  },
  sendfeedback: {
    de: "Feedback abgeben",
    en: "Send feedback",
    it: "Invia feedback"
  },
  next: {
    de: "Weiter",
    en: "Next",
    it: "Prossimo"
  },
  continue: {
    de: "Fortfahren",
    en: "Continue",
    it: "Continuare"
  },
  station_success: {
    de: "Du hast die Station ${1} geschafft, weiter so!",
    en: "You have completed station ${1}, keep it up!",
    it: "Hai completato la stazione ${1}, continua così!"
  },
  stationsuccess_count: {
    de: "${1} von ${2} Stationen geschafft!",
    en: "${1} of ${2} stations completed!",
    it: "${1} su ${2} stazioni completate!"
  },
  viewagain: {
    de: "Nochmal ansehen",
    en: "View again",
    it: "Guarda di nuovo"
  },
  imagecredit: {
    de: "Bild",
    en: "Image",
    it: "Foto"
  },
  sharewithfriends: {
    de: "Mit Freunden teilen",
    en: "Share with friends",
    it: "Condividi con amici"
  },
  usephoto: {
    de: "Foto aufnehmen",
    en: "Take photo",
    it: "Scattare una foto"
  },
  removephoto: {
    de: "Foto entfernen",
    en: "Remove photo",
    it: "Rimuovi foto"
  },
  shareimage: {
    de: "Bild teilen",
    en: "Share image",
    it: "Condividi immagine"
  },
  errorheadline: {
    de: "Oje, Seite nicht gefunden",
    en: "Oops, page not found",
    it: "Ops, pagina non trovata"
  },
  errorbutton: {
    de: "Zurück zum Start",
    en: "Back to home",
    it: "Torna alla home"
  },
  tapholdtosave: {
    de: "Bild gedrückt halten zum Speichern",
    en: "Tap and hold the image to save",
    it: "Tieni premuto sull'immagine per salvare"
  },
  socialshareprivacy: {
    de: "Deine Fotos werden nirgends hochgeladen oder gespeichert",
    en: "Your photos will not be uploaded or saved anywhere.",
    it: "Le tue foto non vengono salvate da nessuna parte."
  },
  socialshareerrormessage: {
    de: "Das Bild konnte nicht geteilt werden. Versuchen Sie, das Bild zu tippen und gedrückt zu halten, um es zu speichern.",
    en: "Could not share the image. Try to tap and hold the image to save it.",
    it: "Impossibile condividere l'immagine. Prova a toccare e tenere premuta l'immagine per salvarla."
  },
  taptocollect: {
    de: "Tippe zum Einsammeln",
    en: "Tap to collect",
    it: "Tocca per raccogliere"
  },
  sendfeedback: {
    de: "Feedback senden",
    en: "Send Feedback",
    it: "Invia feedback"
  },
  choosestartingpoint: {
    de: "Wähle deinen Startpunkt",
    en: "Choose your starting point",
    it: "Scegli il tuo punto di partenza"
  },
  startfromhere: {
    de: "Von hier starten",
    en: "Start from here",
    it: "Inizia da qui"
  }

};

export default tl;