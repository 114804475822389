import { useState, useRef, useEffect } from 'react';
import './station.css';

import arrowimg from "./img/arrow.svg";
import lockimg from "./img/lock.svg";

import ContentVideo from './contenttypes/ContentVideo';
import ContentQuiz from './contenttypes/ContentQuiz';
import ContentImage from './contenttypes/ContentImage';
import ContentCamera from './contenttypes/ContentCamera';
import ContentAudio from './contenttypes/ContentAudio-leiste';
import ContentExternal from './contenttypes/ContentExternal';
import ContentGame from './contenttypes/ContentGame';
import ContentScratch from './contenttypes/ContentScratch.js';

import StationSuccess from './StationSuccess';

import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import LoadingFade from './LoadingFade';

import { handleApiResponse } from './functions/handleApiResponse.js';

import { translate } from './functions/translationHelper';


function Station() {

  let { regionid, stationid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const [index, setIndex] = useState(0);

  let mockdata = {
    title: "Der Auerhahn",
    image: "...",
    language: "de",
    contents: []
  }

  const [showButtons, setShowButtons] = useState(true);
  

  const [lockedContents, setLockedContents] = useState([]);
  console.log(lockedContents);

  const [entryTime, setEntryTime] = useState(Date.now());

  const handleContentCompletion = () => {
    const updatedLockedContents = [...lockedContents];
    updatedLockedContents[index] = false;
    setLockedContents(updatedLockedContents);
    //setTimeout(() => indexnext(), 800);
  };

  function indexprev() {
    if (index > 0) {
      setIndex(prev => prev - 1);
    }
  }

  function indexnext() {
    if (index < newcontents.length && !lockedContents[index]) {
      setIndex(prev => prev + 1);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);



  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}getContents.php?region=${regionid}&stationid=${stationid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      if(data.code !== undefined) {
        handleApiResponse(data.code, regionid, navigate);
      } else {
        setData(data);
        //setLockedContents(newcontents?.map(item => item.type === "quiz" || item.type === "game"));
        setLockedContents(newcontents?.map(item => false));
        setLoading(false);
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  let newcontents = JSON.parse(data?.newcontents || "[]");
  console.log(newcontents);


  return (
    loading ? <LoadingScreen/> : (
    <div className="page-station">
         <LoadingFade/>
        {newcontents.map((content, idx) => {
          let contentdata = content?.content;
          return (
          <div key={idx} className={`content-wrapper ${idx != index ? (idx > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            {content.type === 'video' && <ContentVideo content={contentdata} active={idx === index} baseurl={data.baseurl} onEnd={indexnext} />}
            {content.type === 'audio' && <ContentAudio content={contentdata} active={idx === index} baseurl={data.baseurl} onEnd={indexnext} />}
            {content.type === 'quiz' && <ContentQuiz content={contentdata} onCompletion={handleContentCompletion} baseurl={data.baseurl} />}
            {content.type === 'image' && <ContentImage content={contentdata} baseurl={data.baseurl} />}
            {content.type === 'camera' && <ContentCamera content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'external' && <ContentExternal content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'game' && <ContentGame content={contentdata} onCompletion={() => console.log("Game done")} onNext={indexnext} active={idx === index} baseurl={data.baseurl} language={data.language} showButtons={x => setShowButtons(x)} />}
            {content.type === 'scratch' && <ContentScratch content={contentdata} active={idx === index} baseurl={data.baseurl} showButtons={x => setShowButtons(x)} />}
            {/* Add more conditions for other content types if needed */}
          </div>
        )
        })}

        <div key={'successitem'} className={`content-wrapper ${newcontents.length != index ? (newcontents.length > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            <StationSuccess title={data.title} baseurl={data.baseurl} contenturl={data?.contenturl} trailid={data.trailid} startAgain={() => setIndex(0)} active={newcontents.length === index} numcontents={newcontents.length} language={data.language} entrytime={entryTime || 0} stations={data?.stations} />
        </div>
      
      {/*contents[index].type === 'video' && <ContentVideo key={index} content={contents[index].content} />
      contents[index].type === 'quiz' && <ContentQuiz key={index} content={contents[index].content} />*/}

      <div className={`contentarrowcontainer ${((newcontents.length <= index) || !showButtons) && 'contentarrowcontainerhidden'}`}>
        <div className='clickable stationbtn stationprevbtn' style={{opacity: index > 0 ? 1 : 0}}  onClick={indexprev}><img src={arrowimg} /></div>
        <div className='clickable stationbtn stationnextbtn'  onClick={indexnext}>{translate("next", data.language)}{lockedContents[index] ? <img src={lockimg} /> : <img className='arrowmove' src={arrowimg} />}</div>
      </div>

      <style>{"body{overflow:hidden;}"}</style>
    </div>
    )
  );
}

export default Station;
