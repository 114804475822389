// Audio Player with second buttons

import { useState, useRef, useEffect } from 'react';
import ScratchCard from 'react-scratchcard-v3';

import myimg from '../img/scratchdark.png';
import scratchicon from '../img/scratchicon.svg';

function ContentScratch(props) {

  const [isTriggered, setIsTriggered] = useState(false);


  useEffect(() => {
    if (!props.active) {
    
    }
  }, [props.active])

  return (
    <div className="page-content-scratch">

      <div className={`scratchicon ${isTriggered && "scratchiconremoved"}`} ><img src={scratchicon} className='scratchiconimg' />{props.content.text && <p>{props.content?.text}</p>}</div>
      
      <ScratchCard
        width={window.innerWidth}
        height={window.innerHeight}
        image={myimg}
        finishPercent={40}
        onComplete={() => props.showButtons(true)}
        brushSize={32}
        onTrigger={() => setIsTriggered(true)}
      >
          <img className='scratchbackground' src={props.baseurl + props.content.url} />
    
      </ScratchCard>
      
      {/*<img className='scratchbackground' src={props.content.url} />*/}

    </div>
  );
}

export default ContentScratch;
