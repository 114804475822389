

function PositionDot(props) {
  return (
    <div className={"positiondotcont"}>
        <div className={"positiondotcircle"}></div>
        {/*<div className={"positiondotorientation"} style={{rotate: props.orientation + "deg", opacity: props.orientation >= 0 ? 1 : 0}}></div>*/}
        <div className={"positiondot"}></div>
    </div>
  );
}

export default PositionDot;
