import { useState, useRef, useEffect } from 'react';
import './station.css';

import arrowimg from "./img/arrow.svg";
import lockimg from "./img/lock.svg";

import ContentVideo from './contenttypes/ContentVideo';
import ContentQuiz from './contenttypes/ContentQuiz';
import ContentImage from './contenttypes/ContentImage';
import ContentCamera from './contenttypes/ContentCamera';
import ContentAudio from './contenttypes/ContentAudio-leiste';
import ContentExternal from './contenttypes/ContentExternal';
import ContentGame from './contenttypes/ContentGame';

import StationSuccess from './StationSuccess';

import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import LoadingFade from './LoadingFade';

import { handleApiResponse } from './functions/handleApiResponse.js';

import { translate } from './functions/translationHelper';
import ContentScratch from './contenttypes/ContentScratch';


function Teststation() {

  let { regionid, stationid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const [index, setIndex] = useState(0);

  

  const [lockedContents, setLockedContents] = useState([]);
  console.log(lockedContents);

  const handleContentCompletion = () => {
    const updatedLockedContents = [...lockedContents];
    updatedLockedContents[index] = false;
    setLockedContents(updatedLockedContents);
  };

  function indexprev() {
    if (index > 0) {
      setIndex(prev => prev - 1);
    }
  }

  function indexnext() {
    if (index < data.contents.length && !lockedContents[index]) {
      setIndex(prev => prev + 1);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const testdata = {
    contents: [
      {
        type: "scratch",
        content: {
          url: "https://media.cntraveler.com/photos/58de89946c3567139f9b6cca/4:3/w_4844,h_3633,c_limit/GettyImages-468366251.jpg",
          text: "Wie sieht denn eine Pferdesandale aus?"
          //backgroundcolor: '#fca503'
        }
      },
    {
      type: "audio",
      content: {
        url: "https://content.wunderweg.net/wunderweg/1/1/audio-de.mp3",
        imageurl: "https://media.cntraveler.com/photos/58de89946c3567139f9b6cca/4:3/w_4844,h_3633,c_limit/GettyImages-468366251.jpg",
        //backgroundcolor: '#fca503'
      }
    },
    {
      type: "camera",
      content: {
        sticker: "https://content.wunderweg.net/wunderweg/1/3/sticker.png"
      }
    },
    {
      type: "image",
      content: {
        url: "https://media.cntraveler.com/photos/58de89946c3567139f9b6cca/4:3/w_4844,h_3633,c_limit/GettyImages-468366251.jpg"
      }
    },
    {
      type: "quiz",
      content: {
        question: "Welche Rune findest du auf dem Stein?",
        options: [
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune1.png' },
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune4.png' },
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune2.png' },
          { correct: true, image: 'https://content.wunderweg.net/wunderweg/1/1/rune3.png' },
        ],
        twocolumn: true
      }
    },
    {
      type: "video",
      content: {
        url: "https://content.wunderweg.net/wunderweg/1/1/auerhahn-de.mp4"
      }
    },
    {
      type: "quiz",
      content: {
        question: "Wie verdaut der Auerhahn seine Nahrung?",
        options: [
          { text: "Mit kleinen Steinchen im Magen", smalltext: true, correct: true, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz1.png', imagecover: true },
          { text: "Es wird von anderen Hähnen vorgekaut", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz2.png', imagecover: true },
          { text: "Er zerkaut sie mit seinen Zähnen", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz3.png', imagecover: true },
          { text: "Mit sehr starker Magensäure", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz4.png', imagecover: true },
        ],
        twocolumn: true
      }
    },
    {
      type: "quiz",
      content: {
        question: "Wie hoch ist der Stuibenfall?",
        options: [
          { text: "50m", correct: false, },
          { text: "90m", correct: false, },
          { text: "120m", correct: true, },
          { text: "240m", correct: false, },
        ],
        twocolumn: false
      }
    }
    
  ]}

  const fetchData = async () => {
    setLoading(true);
    try {

        setData(testdata);
        setLockedContents(testdata?.contents.map(item => item.type === "quiz" || item.type === "game"));
        setLoading(false);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    loading ? <LoadingScreen/> : (
    <div className="page-station">
         <LoadingFade/>
        {data?.contents?.map((content, idx) => {
          let contentdata = content?.content;
          return (
          <div key={idx} className={`content-wrapper ${idx != index ? (idx > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            {content.type === 'video' && <ContentVideo content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'audio' && <ContentAudio content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'quiz' && <ContentQuiz content={contentdata} onCompletion={handleContentCompletion} baseurl={data.baseurl} />}
            {content.type === 'image' && <ContentImage content={contentdata} baseurl={data.baseurl} />}
            {content.type === 'camera' && <ContentCamera content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'external' && <ContentExternal content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'game' && <ContentGame content={contentdata} onCompletion={handleContentCompletion} onNext={indexnext} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'scratch' && <ContentScratch content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {/* Add more conditions for other content types if needed */}
          </div>
        )
        })}

        <div key={'successitem'} className={`content-wrapper ${data.contents.length != index ? (data.contents.length > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            <StationSuccess title={data.title} baseurl={data.baseurl} trailid={data.trailid} startAgain={() => setIndex(0)} active={data.contents.length === index} numcontents={data.contents.length} language={data.language} />
        </div>
      
      {/*contents[index].type === 'video' && <ContentVideo key={index} content={contents[index].content} />
      contents[index].type === 'quiz' && <ContentQuiz key={index} content={contents[index].content} />*/}

      <div className={`contentarrowcontainer ${data.contents.length <= index && 'contentarrowcontainerhidden'}`}>
        <div className='clickable stationbtn stationprevbtn' style={{opacity: index > 0 ? 1 : 0}}  onClick={indexprev}><img src={arrowimg} /></div>
        <div className='clickable stationbtn stationnextbtn'  onClick={indexnext}>{translate("next", data.language)}{lockedContents[index] ? <img src={lockimg} /> : <img className='arrowmove' src={arrowimg} />}</div>
      </div>

      <style>{"body{overflow:hidden;}"}</style>
    </div>
    )
  );
}
export default Teststation;
