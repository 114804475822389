import icon_nolocation from "./img/nolocation.svg";
import icon_letsgo from "./img/notifications/fire-animated-2.gif";
import icon_laststation from "./img/notifications/finish.png";
import icon_done from "./img/notifications/party.gif";

const notificationtemplates = {
  nolocation: {
    icon: icon_nolocation,
    // headline: {
    //   de: "Kein Standort",
    //   en: "No Location",
    //   it: ""
    // },
    text: {
      de: "Aktiviere deinen Standort, um das Spiel zu starten",
      en: "Activate your location to play the game",
      it: "Attiva la tua posizione per giocare"
    },
    button: {
      de: "So geht's",
      en: "See how it works",
      it: "Guarda come funziona"
    },
    dismissable: false  
  },
  letsgo: {
    icon: icon_letsgo,
    headline: {
      de: "Los geht's!",
      en: "Let's go!",
      it: "Andiamo!"
    },
    text: {
      de: "Die erste Station wartet auf dich!",
      en: "The first station awaits you!",
      it: "La prima stazione ti aspetta!"
    },
    dismissable: true  
  },
  laststation: {
    icon: icon_laststation,
    headline: {
      de: "Gleich geschafft!",
      en: "Almost there!",
      it: "Quasi arrivato!"
    },
    text: {
      de: "Nur noch eine Station...",
      en: "Only one station left...",
      it: "Solo una stazione rimasta..."
    },
    dismissable: true  
  },
  done: {
    icon: icon_done,
    headline: {
      de: "Abzeichen freigeschaltet!",
      en: "Badge unlocked!",
      it: "Distintivo sbloccato!"
    },
    text: {
      de: "Gehe zum Abzeichen, um es einzusammeln.",
      en: "Go close to the badge to collect it.",
      it: "Vai al distintivo per raccoglierlo."
    },
    dismissable: true  
  }

};

export default notificationtemplates;